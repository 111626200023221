/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
	    /**
	     * Responsive navigation activation
	     * @author Igor Hrcek
	     * @date    2016-04-14
	     * @version 1.0.0
	     */
	    $(".btn-resposnive-navigation").on("click", function(e){
	        e.preventDefault();
	        $(".mint-main-content").toggleClass("st-effect-4 mint-main-content-menu-open");
	    });
	
		/**
		 * Active state hack for main navigation
		 * @author Igor Hrcek
		 * @date    2016-04-05
		 * @version 1.0.0
		 */
		$(".mint-main-navigation li.dropdown a").hover(function() { 
			$(this).click();
		});

		/**
		 * Active state hack for floating navigation bar
		 * @author Igor Hrcek
		 * @date    2016-04-05
		 * @version 1.0.0
		 */
		$(".mint-floating-navigation li.dropdown").hover(function() { 
			$(this).toggleClass("open");
		});	

		/**
		 * Active state hack for top navigation bar
		 * @author Igor Hrcek
		 * @date    2016-04-05
		 * @version 1.0.0
		 */
		$(".mint-top-navigation li.dropdown > a").click(function(e) { 
			e.preventDefault();
			$(this).parent().toggleClass("open active");
		});	

		/**
		 * Open sub-nav items on Blog navigation
		 * @author Igor Hrcek
		 * @date    2018-08-05
		 * @version 1.0.0
		 */
		$("#mint-blog-navigation li.dropdown").click(function(e) { 
			if(!$(this).hasClass("open")) {
				$("#mint-blog-navigation li.dropdown").each(function() {
					$(this).removeClass("open");
					$("ul.dropdown-menu", this).css("display", "none");
				});

				$(this).find('.dropdown-menu').velocity("slideDown", { delay: 100, duration: 200 }, "easeInOutExpo");
				$(this).addClass("open");
			} else {
				$(this).removeClass("open");
				$(this).find('.dropdown-menu').velocity("slideUp", { delay: 100, duration: 200 }).stop();
			}
		});

		/**
		 * sub-nav items on Blog navigation
		 * Based on YAMM documentation: this is the way for preventing unexpected/unwanted menu close 
		 * 
		 * https://geedmo.github.io/yamm/ 
		 */
		$("#mint-blog-navigation ul.dropdown-menu").click(function(e) {
			e.stopPropagation();
		});

		/**
		 * Open sub-nav items on responsive navigation
		 * @author Igor Hrcek
		 * @date    2016-04-05
		 * @version 1.0.0
		 */
		$("#menu-responsive-navigation li.dropdown > a").click(function(e) { 
			e.preventDefault();
			$(this).parent().toggleClass("open active");
		});	

		/**
		 * Floating navigation bar visual games
		 * @author Igor Hrcek
		 * @date    2016-04-05
		 * @version 1.0.0
		 */
		$(".mint-floating-navigation .navbar-nav").click(function() { 
			$(".mint-floating-navigation-overlay").velocity({
				properties: {
					opacity: 1,
					display: "block"
				},
				options: {
					duration: 200, 
					easing: "swing",
					display: "block"
				}
			});
		}, function() { 
			$(".mint-floating-navigation-overlay").velocity({
				properties: {
					opacity: 0,
					display: "none"
				},
				options: {
					duration: 100, 
					easing: "swing",
					display: "none"
				}
			});	
		});		

		/**
		 * Floating top navigation affix
		 * @author Igor Hrcek
		 * @date    2016-04-08
		 * @version 1.0.0
		 */
	    jQuery(window).scroll(function(){
	    	//Determine where we are and where the hell are we going (fire event at the bottom of the page)    	
			if(jQuery(window).scrollTop() > 250) {
				$(".mint-floating-navigation-container").addClass("visible");	
	    	} 
	    	if(jQuery(window).scrollTop() < 250) {
				$(".mint-floating-navigation-container").removeClass("visible");	
			}
		});	

		/**
		 * Floating sidebar navigation on KB (baza znanja)
		 */
		if ($(".mint-baza-znanja-nav")[0]){

			// variables: top position for navigation, navigation height, footer distance from the top
			var topPosition = $('.mint-baza-znanja-nav').offset().top - 173;
			var floatingDivHeight = $('.mint-baza-znanja-nav').outerHeight();
			var footerFromTop = $('.mint-footer').offset().top;
			
			if($('.mint-fiksna-navigacija').outerHeight() > floatingDivHeight) {

				if(window.matchMedia('(min-width: 768px)').matches) $('.mint-baza-znanja-nav-wrapper').css("height", $('.mint-fiksna-navigacija').outerHeight());

				$(window).scroll(function(){
					if (window.matchMedia('(min-width: 768px)').matches) {
						// we need to set this again, in case some dropdown nav is clicked
						var absPosition = footerFromTop - $('.mint-baza-znanja-nav').outerHeight() - 120 - 173;

						if (($(window).scrollTop() > topPosition) && ($(window).scrollTop() < absPosition)) {
							$('.mint-baza-znanja-nav').addClass('mint-baza-znanja-fixed');
							$('.mint-baza-znanja-nav').removeClass('mint-baza-znanja-absolute');
	
						} else if (($(window).scrollTop() > topPosition) && ($(window).scrollTop() > absPosition)) {
							$('.mint-baza-znanja-nav').removeClass('mint-baza-znanja-fixed');
							$('.mint-baza-znanja-nav').addClass('mint-baza-znanja-absolute');
	
						} else {
							$('.mint-baza-znanja-nav').removeClass('mint-baza-znanja-fixed');
							$('.mint-baza-znanja-nav').removeClass('mint-baza-znanja-absolute');
						}
					}
				});
			}
		}

		$('#mint-bad-article-review').click(function(e) {
			$('.mint-baza-znanja-comment-area').toggleClass("visible");
			$(this).toggleClass("active");
		});

		$('#mint-good-article-review').click(function(e) {
			// e.preventDefault();
			$('.mint-baza-znanja-comment-area').removeClass("visible");
			$('#mint-bad-article-review').removeClass("active");
		});

	    /**
	     * Live Chat tooltip
	     */
	    $(".mint-floating-live-support").tooltip();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
	},
	'home': {
		init: function() {
			if($('.mint-homepage-testimonials').length > 0) {
				var $carousel =  $('.mint-homepage-testimonials').flickity({
					cellAlign: 'center',
					contain: true,
					initialIndex: 1,
					pageDots: true,
					autoPlay: 3500,
					pauseAutoPlayOnHover: true,
					prevNextButtons: false,
					wrapAround: true
				});    
				var flkty = $carousel.data('flickity');
			}
		}
	},
    'page_template_page_service': {
      init: function() {
		/**
		 * Tooltip init for specification tables
		 */
		$(".table-specifications [data-toggle='popover']").popover();

		$(".mint-hero .btn-hero").on("click", function (e) {
			e.preventDefault();
			
			console.log($(".mint-product-pricing").offset());
			$([document.documentElement, document.body]).animate({
				scrollTop: $(".mint-product-pricing").offset().top-140
			}, 1000);		
		});

	    /**
	     * Specification table expander
	     * @author Igor Hrcek
	     * @date    2016-04-08
	     * @version 1.0.0
	     */
	    $(".mint-product-specifications-expander").on("click", function(e){
	    	e.preventDefault();
	    	var ele = $(this).data("target");
	   		$("."+ele).toggleClass("expanded");
	   		$(this).toggleClass("expanded");
	    });

	    /**
	     * Used for pricing in tables (ex VPS pricing table)
	     */
	    $(".btn-pricing-more").on("click", function(e) {
	    	e.preventDefault();
	    	$(this).parent().find(".row").velocity({
	 			properties: {
					opacity: 0,
					display: "none",
					height: 0
				},
				options: {
					duration: 200, 
					easing: "swing",
					display: "none"
				}   		
	    	});
	    	$(this).parent().find("table").toggleClass("visible");
	    	$(this).remove();
	    });

	    /**
	     * Product carousel (used on VPS page)
	     */
	    if($('.mint-product-carousel').length > 0) {
			var $carousel =  $('.mint-product-carousel').flickity({
				cellAlign: 'center',
				contain: false,
			 	initialIndex: 2,
				pageDots: false,
			});    
			var flkty = $carousel.data('flickity');
			var p = flkty.selectedIndex - 1;
			var n = flkty.selectedIndex + 1;
			$('.mint-product-carousel .carousel-cell:eq('+p+') .mint-card-pricing').toggleClass("is-previous");
			$('.mint-product-carousel .carousel-cell:eq('+n+') .mint-card-pricing').toggleClass("is-next");

			$('.mint-product-pricing .btn-product-pricing').attr('href', $('.mint-product-carousel .carousel-cell:eq('+flkty.selectedIndex+') .mint-card-pricing').data('url'));

			$carousel.on( 'select.flickity', function() {
				var i = flkty.selectedIndex;
				var p = i - 1;
				var n = i + 1;
				$('.mint-product-carousel .carousel-cell').each(function() {
					$('.mint-card-pricing', this).removeClass('is-previous');
					$('.mint-card-pricing', this).removeClass('is-next');
				});

				$('.mint-product-carousel .carousel-cell:eq('+p+') .mint-card-pricing').toggleClass("is-previous");
				$('.mint-product-carousel .carousel-cell:eq('+n+') .mint-card-pricing').toggleClass("is-next");

				$('.mint-product-pricing .btn-product-pricing').attr('href', $('.mint-product-carousel .carousel-cell:eq('+i+') .mint-card-pricing').data('url'));
			});	   
	    }

	    /**
	     * Poravnanje kartica po visini
	     * @author Igor Hrcek
	     * @date    2016-07-30
	     */
	    if($(document).width() > 768) {

		    if($(".mint-card-pricing")) {
		        //$(".mint-card-pricing").matchHeight();
		    }

		    // if($(".mint-block-small-feature")) {
		    //     $(".mint-block-small-feature").matchHeight({ 'byRow': false });
		    // }   	

		    if($(".mint-card-showcase-50")) {
		    	$(".mint-card-showcase-50").matchHeight();
		    }
	    }	    

      }
	},
	
    'page_template_page_lead_forms': {
    	init: function() {

			document.addEventListener( 'wpcf7mailsent', function( event ) {
				ga('send', {
					hitType: 'event',
					eventCategory: 'Lead Form',
					eventAction: 'Submit',
					eventLabel: $('name["lead-source"]').val()
				  });
			}, false );

    	}  	
	},

    'category_blog': {
    	init: function() {
			var $carousel =  $('.mint-blog-hero-content').flickity({
				cellAlign: 'left',
				contain: true,
				initialIndex: 0,
				pageDots: true,
				autoPlay: 5000,
				pauseAutoPlayOnHover: true,
				prevNextButtons: false,
				wrapAround: true
			});    
			var flkty = $carousel.data('flickity');
    	}  	
	},
	
	'single_post': {
		init: function() {
			/**
			 * Floating top newsletter bar
			 * @author Igor Hrcek
			 * @date    2018-03-16
			 */
			jQuery(window).scroll(function(){
				//Determine where we are and where the hell are we going (fire event at the bottom of the page)    	
				if(jQuery(window).scrollTop() > 450) {
					$("#mint-blog-nl-sigunp-form").addClass("visible");
					
				} 

				if(jQuery(window).scrollTop() < 450) {
					$("#mint-blog-nl-sigunp-form").removeClass("visible");
				}

				if(jQuery(window).scrollTop() > $(document).height()*0.3) {
					$(".mint-blog-single-mobile-nextprev").addClass("visible");
				}

				if(jQuery(window).scrollTop() < $(document).height()*0.3) {
					$(".mint-blog-single-mobile-nextprev").removeClass("visible");
				}
			});

			/**
			 * Selection Share
			 */
			$('p').selectionSharer();
		}
	},

    'page_template_page_porucivanje': {
    	init: function() {
			/**
			 * Tooltip init for ordering
			 */
			$(".mint-ordering-tooltip").tooltip();

		    /**
		     * Floating ordering sidebar affix
		     * @author Igor Hrcek
		     * @date    2016-04-08
		     * @version 1.0.0
		     */

		    jQuery(window).scroll(function() {
		    	if ($(window).width() < 990) { return false; } //no scroll attachment for tablet and mobile

		    	var f = $(".mint-footer").height();
		    	var o = $(".mint-ordering .row").outerHeight(true);
		    	var t = $(".mint-ordering-sidebar").height();
		    	var b = $(".btn-ordering-next").outerHeight(true);
		    	var d = $(document).height();
		    	var w = $(window).height();
		    	var s = jQuery(window).scrollTop();
		    	var poss = $(".mint-ordering .row").position();
		    	var cor = 0;

				if((d - (w + s)) - f < 230) {
		    		$(".mint-ordering-sidebar").css({"position" : "relative", "top" : (o - poss.top - (t/2)) + cor});
		    	} else {
		    		$(".mint-ordering-sidebar").removeAttr("style");
		    	}
		    });

		    /**
		     * Shopping cart checkbox form control
		     * @author Igor Hrcek
		     * @date    2016-04-14
		     * @version 1.0.0
		     */
		    // $("#mint-checkout-company-checkbox").on("click", function(e) {
		    // 	var v = $(this).is(":checked");
		    	
		    // 	if (v === true) {
			   //  	$(".mint-checkout-company").velocity({
			   //  		properties: {
			   //  			opacity: 1,
			   //  		},
			   //  		options: {
			   //  			duration: 200,
			   //  			easing: "swing",
			   //  			display: "block"
			   //  		}
			   //  	});
		    // 	} else {
			   //  	$(".mint-checkout-company").velocity({
			   //  		properties: {
			   //  			opacity: 0,
			   //  		},
			   //  		options: {
			   //  			duration: 200,
			   //  			easing: "swing",
			   //  			display: "none"
			   //  		}
			   //  	});    		
		    // 	}
		    // });
	        		
    	}
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

